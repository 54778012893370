<template>
  <vue-slider
    v-model="value"
    :data-id="sliderId"
    class="ui-layer-slider tran cursor-pointer select-none"
    :class="{
      'pointer-events-none': data.length === 1,
    }"
    tooltip="none"
    :data="data"
    :drag-on-click="true"
    :contained="true"
    :process-style="processStyle"
    :rail-style="railStyle"
    :disabled="disabled"
  />
</template>

<script setup>
import VueSlider from 'vue-3-slider-component';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  data: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue']);
const sliderId = ref(0);
const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
const processStyle = {
  'background-color': '#F7E017',
};
const railStyle = {
  height: '4px',
};

const setSliderFilled = async () => {
  const dot = document.querySelector(
    `.vue-slider.ui-layer-slider[data-id='${sliderId.value}'] > .vue-slider-rail > .vue-slider-dot`,
  );
  if (!dot) return;
  const progress = document.querySelector(
    `.vue-slider.ui-layer-slider[data-id='${sliderId.value}'] > .vue-slider-rail > .vue-slider-process`,
  );
  if (!progress) return;
  await nextTick();
  dot.style.left = '100%';
  progress.style.width = '100%';
};

const generateID = computed(() => {
  return Math.floor(Math.random() * 1000);
});

onBeforeMount(() => {
  sliderId.value = props.type + generateID.value;
});

onMounted(async () => {
  if (props.data?.length === 1) {
    await setSliderFilled();
  }
});

watch(
  () => [props.data, props.modelValue],
  async (nv) => {
    if (nv[0]?.length === 1) {
      await setSliderFilled();
    }
  },
);
</script>

<style>
.vue-slider.ui-layer-slider {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.vue-slider.ui-layer-slider .vue-slider-rail,
.vue-slider.ui-layer-slider .vue-slider-rail::before {
  background-color: #f2f1f7 !important;
}

.vue-slider.ui-layer-slider .vue-slider-rail::before,
.vue-slider.ui-layer-slider .vue-slider-process::before {
  width: 15px;
  height: 4px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  content: '';
}

.vue-slider.ui-layer-slider .vue-slider-rail::before {
  right: -10px;
}

.vue-slider.ui-layer-slider .vue-slider-process::before {
  background-color: #f7e017 !important;
  left: -10px;
}

.vue-slider.ui-layer-slider .vue-slider-dot,
.vue-slider.ui-layer-slider .vue-slider-dot:focus {
  border-radius: 100%;
  width: 20px !important;
  height: 20px !important;
  border: 6px solid #f7e017 !important;
  box-shadow: none !important;
}

.vue-slider.ui-layer-slider .vue-slider-dot-handle {
  box-shadow: none !important;
}

@media (min-width: 1024px) {
  .vue-slider.ui-layer-slider .vue-slider-dot,
  .vue-slider.ui-layer-slider .vue-slider-dot:focus {
    width: 24px !important;
    height: 24px !important;
    border: 7px solid #f7e017 !important;
  }

  .vue-slider.ui-layer-slider {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .vue-slider.ui-layer-slider .vue-slider-rail::before {
    right: -12px;
  }

  .vue-slider.ui-layer-slider .vue-slider-process::before {
    background-color: #f7e017 !important;
    left: -12px;
  }
}
</style>
