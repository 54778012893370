<template>
  <div
    class="flex flex-col rounded-2xl bg-ui_white text-sm text-ui_black lg:rounded-2.5xl lg:text-base"
  >
    <div class="mb-4.5 flex items-center justify-between lg:mb-6">
      <p
        class="select-none"
        :class="{
          label: !labelClasses,
          [labelClasses]: labelClasses,
        }"
      >
        {{ label }}
      </p>
      <div
        ref="editableInputBoxRef"
        class="relative rounded-2xl px-3 py-1 font-bold lg:px-4 lg:py-[6px]"
        :class="{
          'cursor-default': valueLabel,
          'cursor-pointer': !valueLabel && editInput,
          'value-label-background-color': !valueLabelBackgroundColorClasses,
          [valueLabelBackgroundColorClasses]: valueLabelBackgroundColorClasses,
        }"
        @click="setInputCaret"
      >
        <div
          v-if="valueLabel"
          :class="{
            'value-label': !valueLabelClasses,
            [valueLabelClasses]: valueLabelClasses,
          }"
          class="flex items-center gap-1"
        >
          {{ valueLabel }}
        </div>
        <div
          v-else
          class="flex items-center gap-1"
        >
          <div
            ref="editableInputRef"
            class="select-none outline-none"
            :class="{ 'cursor-text': editInput }"
            :contenteditable="editInput"
            spellcheck="false"
            @input.prevent="inputHandler"
            @keypress.enter="enterHandler"
            @blur="emitInputValue()"
          />
          <span
            v-if="type === 'summ'"
            class="select-none"
          >
            ₽
          </span>
          <UiIcon
            v-if="editInput"
            name="edit"
            class="h-2.5 w-2.5 select-none"
          />
        </div>
      </div>
    </div>
    <UiSlider
      v-model="sliderValue"
      :type="type"
      :data="data"
      class="mb-4"
      :disabled="disabled"
    />
    <div
      v-if="dataBorders.length"
      class="flex select-none items-center justify-between"
      :class="{
        'data-borders': !dataBordersClasses,
        [dataBordersClasses]: dataBordersClasses,
      }"
    >
      <span>{{ dataBorders[0] }}</span>
      <span>{{ dataBorders[1] }}</span>
    </div>
  </div>
</template>

<script setup>
import getCaretPosition from '~/helpers/getCaretPosition';
import setCaret from '~/helpers/setCaret';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  data: {
    type: Array,
    default: () => [],
  },
  dataBorders: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  valueLabel: {
    type: String,
    default: '',
  },
  editInput: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'summ',
  },
  labelClasses: {
    type: String,
    default: '',
  },
  valueLabelBackgroundColorClasses: {
    type: String,
    default: '',
  },
  valueLabelClasses: {
    type: String,
    default: '',
  },
  dataBordersClasses: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['update:modelValue']);

const editableInputRef = ref();
const editableInputBoxRef = ref();

const sliderValue = computed({
  get() {
    return props.type === 'summ' ? Number(props.modelValue) : props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const setInputCaret = (event, element = editableInputRef.value, caretPos) => {
  setCaret(event, element, caretPos);
};

const setInputText = (value, editInput = true) => {
  if (value) {
    let caretPos;
    if (editInput) {
      caretPos = getCaretPosition(editableInputRef.value);
    }
    editableInputRef.value.textContent = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (editableInputRef.value.textContent.length === 5 && caretPos === 4) caretPos = 5;
    if (editInput) setInputCaret(null, editableInputRef.value, caretPos);
  }
};

const inputHandler = (event) => {
  const value = event.target.textContent.replace(/\D/g, '');

  if (value.length > 6) {
    return setInputText(value.slice(0, 6));
  }
  setInputText(value);
};

const enterHandler = (e) => {
  if (e.key !== 'Enter') return;

  editableInputRef.value.blur();
  emitInputValue();
};

const emitInputValue = (value = editableInputRef.value.textContent) => {
  emit('update:modelValue', value.toString().replace(/\D/g, ''));
};

const clickOutsideInput = (e) => {
  if (
    !editableInputRef.value ||
    (editableInputBoxRef.value && editableInputBoxRef.value.contains(e.target))
  )
    return;
  editableInputRef.value.blur();
};

onMounted(() => {
  document.addEventListener('mousedown', clickOutsideInput);
  if (!props.valueLabel) setInputText(props.modelValue, false);
});

onBeforeUnmount(() => {
  document.removeEventListener('mousedown', clickOutsideInput);
});

watch(
  () => props.modelValue,
  (nv) => {
    if (!props.valueLabel) setInputText(nv, false);
  },
);
</script>

<style scoped>
.label {
  @apply text-xxs font-bold xxxs:text-sm lg:text-base;
}

.value-label {
  @apply text-xxs xxxs:text-sm lg:text-base;
}

.value-label-background-color {
  @apply bg-ui_light_yellow;
}

.data-borders {
  @apply text-xxxs text-ui_dark_grey xxxs:text-xs;
}
</style>
