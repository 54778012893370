export default (event, element, offset) => {
  if (!element || !element.childNodes?.[0] || event?.target === element) return;
  if (typeof offset === 'number') {
    offset = offset > element.textContent.length ? element.textContent.length : offset;
  }
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(element.childNodes[0], offset ?? element.textContent.length);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
};
